import styled from 'styled-components'

import { Section as GSSection } from 'components/general-styles'
import { theme } from 'styles/theme'

const { media, space, fontSizes } = theme

export const Section = styled(GSSection)`
  @media (${media.tablet}) {
    padding-top: ${space['spacing-xl']};
    padding-bottom: ${space['spacing-xl']};
  }
`

export const Content = styled.div`
  @media (${media.tablet}) {
    display: flex;
    align-items: center;
    gap: ${space['spacing-xxl']};
  }
`

export const ImageCol = styled.div`
  margin-bottom: ${space['spacing-xxl']};

  @media (${media.tablet}) {
    width: calc(50% - ${space['spacing-sm']});
    min-width: calc(50% - ${space['spacing-sm']});
    margin-bottom: 0;
  }
`

export const Infos = styled.div`
  @media (${media.tablet}) {
    width: calc(50% - ${space['spacing-xs']});
  }

  h3,
  p:not(:last-of-type) {
    margin-bottom: ${space['spacing-md']};
  }

  @media (${media.desktop}) {
    h3 {
      margin-bottom: ${space['spacing-sm']};
      font-size: ${fontSizes['font-size-heading-3']};
    }

    p {
      margin-bottom: ${space['spacing-xl']};
    }
  }
`
