import { FC, memo } from 'react'

import { Text } from '@cash-me/react-components'
import NextImage from "next/image"

import { Grid, Col } from 'components/grid'

import * as S from './styles'

const Concierge: FC = memo(() => (
  <S.Section bg="medium">
    <Grid col={{ phone: 1, tablet: 1 }}>
      <Col col={{ desktop: 10 }} start={{ desktop: 2 }}>
        <S.Content>
          <S.ImageCol>
            <NextImage
              src={`${process.env.NEXT_PUBLIC_IMAGES_PREFIX}/condo/portaria-remota.png?updated=03072022`}
              alt="Portaria remota"
              width="601"
              height="533"
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto"
              }} />
          </S.ImageCol>

          <S.Infos>
            <Text as="h3" size="heading3" weight="medium">
              Portaria Remota
            </Text>
            <Text color="var(--colors-neutral-text-weak)">
              Uma inovação que permite controlar a entrada e saída de pessoas através de uma central de monitoramento.
            </Text>
            <Text weight="medium">Use a tecnologia para reduzir gastos.</Text>
          </S.Infos>
        </S.Content>
      </Col>
    </Grid>
  </S.Section>
))

Concierge.displayName = 'Concierge'

export default Concierge
